import React from 'react'
import styled from 'styled-components';
import ImageSection from '../Components/ImageSection';
import Title from '../Components/Title';
import {MainLayout} from '../styles/Layouts';
// import ServiceSection from '../Components/ServiceSection';
// import ReviewSection from '../Components/ReviewSection';

function AboutPage({theme}) {
    return (
        <MainLayout>
            <AboutStyled >
                <Title title={'About Me'} span={'About Me'} />
                <ImageSection theme={theme}/>
                {/* <ServiceSection /> */}
                {/* <ReviewSection /> */}
            </AboutStyled >
        </MainLayout>
    )
}

const AboutStyled = styled.section`
    
`;

export default AboutPage
